import Previewer from '@salesforce-mc/mobile-previewer';
import { SecurePreview } from '@salesforce-mc/contentbuilder-sdk';
import Template from '../../templates/main.html';
import Header from './whatsapp-header';
import I18n from '../../utilities/i18n';
import DateTime from '../../utilities/date-time';

const whatsapp = function (dom, config) {
	const i18n = new I18n(config.locale);
	const previewerHeader = '#previewer-header';
	const previewerContainer = '#previewer-container';

	const getPage = () => {
		const previewer = new Previewer(this.config);
		const page = previewer.getPage();

		return page || '';
	};

	const setDateTime = () => {
		const datetime = new DateTime(config.locale);

		this.config.date = datetime.getCurrentDate();
		this.config.time = datetime.getCurrentTime();
	};

	const renderSecurePreview = () => {
		const container = dom.querySelector(previewerContainer);

		if (!this.securePreview) {
			this.securePreview = new SecurePreview(container, config.stackKey);
		}

		this.securePreview.setContent(getPage());
	};

	const renderPreviewer = () => {
		setDateTime();
		renderSecurePreview();
	};

	const renderHeader = () => {
		const container = dom.querySelector(previewerHeader);

		this.header = new Header(container, config);
		this.header.onClickOrientation = (response) => {
			this.config.orientation = response;

			if (this.config.orientation === 'landscape') {
				document.querySelector('iframe').classList.add('landscape');
			} else {
				document.querySelector('iframe').classList.remove('landscape');
			}

			renderPreviewer();
		};

		this.header.onSelectScreenPreview = (response) => {
			this.config.screenPreview = response;

			renderPreviewer();
		};
	};

	const render = () => {
		dom.innerHTML = Template;
	};

	const initialize = () => {
		this.config = config || {};
		this.config.i18n = i18n;

		// Default hardcoded config
		this.config.channelName = i18n.get('jsonmessageblock.JSONMESSAGE_WHATSAPP').toUpperCase();
		this.config.timestamp = i18n.get('mobilepush_inapp.now');

		render();
		renderHeader();
		renderPreviewer();
	};

	initialize();

	this.render = (updates) => {
		this.config = updates;
		
		// Default hardcoded config
		this.config.channelName = i18n.get('jsonmessageblock.JSONMESSAGE_WHATSAPP').toUpperCase();
		this.config.timestamp = i18n.get('mobilepush_inapp.now');

		renderPreviewer();
	};
};

export default whatsapp;
