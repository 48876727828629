import Previewer from '@salesforce-mc/mobile-previewer';
import { SecurePreview } from '@salesforce-mc/contentbuilder-sdk';
import Template from '../../templates/main.html';
import Header from './sms-header';
import I18n from '../../utilities/i18n';

const sms = function (dom, config) {
	const i18n = new I18n(config.locale);
	const previewerHeader = '#previewer-header';
	const previewerContainer = '#previewer-container';
	
	const getPage = () => {
		const previewer = new Previewer(this.config);
		const page = previewer.getPage();

		return page || '';
	};

	const renderSecurePreview = () => {
		const container = dom.querySelector(previewerContainer);
		
		if (!this.securePreview) {
			this.securePreview = new SecurePreview(container, config.stackKey);
		}
		
		this.securePreview.setContent(getPage());
	};
		
	const renderPreviewer = () => {
		renderSecurePreview();
	};

	const renderHeader = () => {
		const container = dom.querySelector(previewerHeader);
		
		this.header = new Header(container, config);
	};
	
	const render = () => {
		dom.innerHTML = Template;
	};
	
	const initialize = () => {
		this.config = config || {};
		this.config.i18n = i18n;
		
		render();
		renderHeader();
		renderPreviewer();
	};
	
	initialize();
	
	this.render = (updates) => {
		this.config = Object.assign(this.config, updates);

		renderPreviewer();
	};
};

export default sms;
