import './styles/index.scss';
import '@salesforce-mc/mobile-common/libs/polyfills';
import PreviewSDK from '@salesforce-mc/preview-sdk';
import { DEFAULT_CULTURE_CODE } from '@salesforce-mc/mobile-common/constants';
import McLib from '@salesforce-mc/mc-lib';
import getMCUrl from '@salesforce-mc/mc-url';
import App from './containers/app';
import ParseContent from './utilities/parse-content';
import { DEFAULT_STACK_KEY } from './constants';
import I18n from './utilities/i18n';

window.onload = function () {
	const dom = document.getElementById('mount');
	document.body.classList.add('slds-wcag');
	
	const render = () => {
		dom.classList.add(this.config.channel);
		
		if (this.app) {
			this.app.render(this.config);
		} else {
			this.app = new App(dom, this.config); // at this point this.config contains the parsed config for the channel
		}
	};
	
	const parseContent = () => {
		const cfg = this.config;
		const channel = (cfg.channel) ? cfg.channel.toLowerCase() : '';
		this.config = new ParseContent(cfg).parseChannelConfig(channel);
		
		render();
	};

	const initialize = () => {
		this.config = {};

		let cultureCode;

		const onReady = (config) => {
			console.log('ONREADY CONFIG', config);

			const { userData } = config;
			
			this.config.stackKey = userData.stack;
			
			cultureCode = userData.locale;
		};

		const listeners = {
			onSetData: (data, metaData) => {
				console.log('ONSETDATA DATA', data);
				console.log('JSON.STRINGIFY DATA', JSON.stringify(data));
				console.log('ONSETDATA METADATA', metaData);
				console.log('JSON.STRINGIFY METADATA', JSON.stringify(metaData));
				
				const config = data.customBlockData;
				const { isSubscriberPreview } = metaData || {};

				this.config = Object.assign(this.config, config);
				this.config.isSubscriberPreview = isSubscriberPreview || false;
				this.config.locale = cultureCode;
				this.config.i18n = new I18n(this.config.locale);

				console.log('THIS.CONFIG', this.config);
				
				parseContent();
			}
		};

		try {
			this.previewSDK = new PreviewSDK(listeners, onReady);
		} catch (e) {
			const hasLocalStorage = typeof window.localStorage === 'object';
			
			if (hasLocalStorage) {
				const isDev = localStorage.getItem('dev');
				
				console.log('LOCALSTORAGE DEV', isDev);

				if (isDev === 'true') {
					console.log('LOCALSTORAGE CONFIG', localStorage.getItem('config'));
					
					const config = JSON.parse(localStorage.getItem('config'));

					if (!config) {
						throw Error('localstorage.config does not exists. please reference https://github.exacttarget.com/Mobile/mobilepush-device-previewer#running');
					}
					
					this.config = config.customBlockData;
					this.config.stackKey = config.customBlockData.stackKey || DEFAULT_STACK_KEY;
					this.config.locale = config.customBlockData.locale || DEFAULT_CULTURE_CODE;
					this.config.i18n = new I18n(this.config.locale);
					this.config.isSubscriberPreview = false;
					
					console.log('THIS.CONFIG', this.config);

					parseContent();
				}
			}
		}
	};
	
	try {
		const mcLib = new McLib({
			mc: window.top,
			allowedOrigin: getMCUrl(),
			callback: () => {
				console.log('MobilePushDevicePreviewer: mclib.version = %s', mcLib.version);
				console.log('MobilePushDevicePreviewer: mclib.verifiedMC = %s', mcLib.verifiedMC);

				mcLib.registerLogoutUrl(`${window.location.origin}/logout`);
				
				initialize();
			}
		});
	} catch (e) {
		console.log('No MCLib Available: The app is running in dev mode');
		initialize();
	}
};
