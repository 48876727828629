import { hasAmpscript } from '@salesforce-mc/mobile-common/libs/helper';

const ByteCount = function (content) {
	this.content = content || {};
};

ByteCount.prototype.push = function () {
	const title = this.content.title || '';
	const subtitle = this.content.subtitle || '';
	const message = this.content.message || '';
	const media = (this.content.device === 'android') ? this.content.androidRichMedia : this.content.iosRichMedia;
	const mediaText = (this.content.device === 'android') ? this.content.androidAlternateText : this.content.iosAlternateText;
	const openDirect = this.content.openDirect || '';
	const actionType = this.content.actionType || '';

	const getMediaAltObj = function () {
		const mediaAltlObj = (mediaText) ? `"_mediaAlt": "${mediaText}",` : '';
		
		return mediaAltlObj;
	};
	
	const getMediaUrlObj = function () {
		const mediaUrlObj = (media) ? `"_mediaUrl": "${media}",` : '';
		
		return mediaUrlObj;
	};
	
	const getSenderObj = function () {
		return '"_sender": "mc","_sid": "SFMC",';
	};
	
	const getCategoryObj = function () {
		return '';
	};
	
	const getCustomKeysObj = function () {
		return '';
	};
	
	const getCloudPageObj = function () {
		const isCloudPage = actionType === 'cloudpage';
		const cloudPageObj = (isCloudPage) ? `"_x":"${openDirect}",` : '';
		
		return cloudPageObj;
	};
	
	const getOpenDirectObj = function () {
		const isWebOrUrl = actionType === 'web' || actionType === 'url';
		const openDirectObj = (isWebOrUrl) ? `"_od":"${openDirect}",` : '';
		
		return openDirectObj;
	};
	
	const getMessageIdObj = function () {
		return '"_m":"MTIzNDU2Nzg5MDoxMTQ6MA==",';
	};

	const getContent = function () {
		const titleObj = (title) ? `"title":"${title}",` : '';
		const subtitleObj = (subtitle) ? `"subtitle":"${subtitle}",` : '';
		const messageObj = (message) ? `"body":"${message}"` : '';

		if (!title) {
			return `"alert":"${message}",`;
		}

		return `"alert":{${titleObj}${subtitleObj}${messageObj}},`;
	};

	const getApsObj = function () {
		const content = getContent();
		const soundObj = '"sound":"default",';
		const badgeObj = '"badge":9999,';
		const mediaObj = (media) ? '"mutable-content": 1,' : '';
		const json = `${content}${soundObj}${badgeObj}${mediaObj}`.replace(/,\s*$/, '');

		return `"aps":{${json}},`;
	};

	const getPayload = function () {
		const apsObj = getApsObj();
		const messageIdObj = getMessageIdObj();
		const openDirectObj = getOpenDirectObj();
		const cloudPageObj = getCloudPageObj();
		const customKeysObj = getCustomKeysObj();
		const categoryObj = getCategoryObj();
		const senderObj = getSenderObj();
		const mediaUrlObj = getMediaUrlObj();
		const mediaAltObj = getMediaAltObj();
		const json = `${apsObj}${messageIdObj}${openDirectObj}${cloudPageObj}${customKeysObj}${categoryObj}${senderObj}${mediaUrlObj}${mediaAltObj}`.replace(/,\s*$/, '');
		const payload = `MessagePayload{${json}}`;

		return payload;
	};
		
	if ((hasAmpscript(`${title}`)) || (hasAmpscript(`${subtitle}`)) || (hasAmpscript(`${message}`)) || (hasAmpscript(`${media}`)) || (hasAmpscript(`${mediaText}`)) || (hasAmpscript(`${openDirect}`))) {
		return -1;
	}
	
	const payload = getPayload();
	
	this.payload = payload.replace('MessagePayload', '');
	
	let byteCount = payload.length;
	
	for (let index = 0; index < byteCount; index++) {
		if (payload.charCodeAt(index) > 255) {
			byteCount++;
		}
	}
	
	return byteCount;
};

export default ByteCount;
