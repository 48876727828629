import { DEFAULT_CULTURE_CODE, SUPPORTED_CULTURE_CODE_FILE_NAME_MAP, SUPPORTED_CULTURE_CODE_MOMENT_LOCALE_MAP } from '@salesforce-mc/mobile-common/constants';
import { hasProperty } from '@salesforce-mc/mobile-common/libs/helper';

const Localization = function (locale) {
	this.locale = locale;
	this.loadDictionary();
};

Localization.prototype.loadDictionary = function () {
	const defaultFileName = 'en';
	const fileName = hasProperty(SUPPORTED_CULTURE_CODE_FILE_NAME_MAP, this.locale) ? SUPPORTED_CULTURE_CODE_FILE_NAME_MAP[this.locale] : SUPPORTED_CULTURE_CODE_FILE_NAME_MAP[DEFAULT_CULTURE_CODE];

	try {
		this.dictionary = require(`../i18n/${fileName}.json`); // eslint-disable-line global-require, import/no-dynamic-require
	} catch (e) {
		this.dictionary = require(`../i18n/${defaultFileName}.json`); // eslint-disable-line global-require, import/no-dynamic-require
	}

	this.fallbackDictionary = require(`../i18n/${defaultFileName}.json`); // eslint-disable-line global-require, import/no-dynamic-require
};

Localization.prototype.getLocale = function () {
	return this.locale;
};

Localization.prototype.getMomentLocale = function () {
	return hasProperty(SUPPORTED_CULTURE_CODE_MOMENT_LOCALE_MAP, this.locale) ? SUPPORTED_CULTURE_CODE_MOMENT_LOCALE_MAP[this.locale] : SUPPORTED_CULTURE_CODE_MOMENT_LOCALE_MAP[DEFAULT_CULTURE_CODE];
};

Localization.prototype.get = function (key, lastChance) {
	const keyResult = this.dictionary[key];
	const fallbackResult = this.fallbackDictionary[key];
	const defaultFail = function () {
		return `[[%% ${key} %%]]`;
	};
	const notFound = lastChance || defaultFail;

	if (typeof keyResult !== 'undefined') {
		return keyResult;
	}
	
	if (typeof fallbackResult !== 'undefined') {
		return fallbackResult;
	}

	return (typeof notFound === 'function') ? notFound(key) : notFound;
};

export default function (locale) {
	return new Localization(locale || '');
}
