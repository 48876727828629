import Push from './push';
import InApp from './inapp';
import Sms from './sms';
import Line from './line';
import WhatsApp from './whatsapp';

const app = function (dom, config) {
	const render = () => {
		const channel = (this.config.channel) ? this.config.channel.toLowerCase() : '';
		
		if (channel === 'push') {
			if (this.push) {
				this.push.render(this.config);
			} else {
				this.push = new Push(dom, this.config);
			}
		} else if (channel === 'inapp') {
			this.inapp = new InApp(dom, this.config);
		} else if (channel === 'sms') {
			if (this.sms) {
				this.sms.render(this.config);
			} else {
				this.sms = new Sms(dom, this.config);
			}
		} else if (channel === 'line') {
			if (this.line) {
				this.line.render(this.config);
			} else {
				this.line = new Line(dom, this.config);
			}
		} else if (channel === 'whatsappsession' || channel === 'whatsapptemplate') {
			if (this.whatsapp) {
				this.whatsapp.render(this.config);
			} else {
				this.whatsapp = new WhatsApp(dom, this.config);
			}
		}
	};
	
	const initialize = () => {
		this.config = config || {};
		
		render();
	};
	
	initialize();
	
	this.render = (updateConfig) => {
		this.config = updateConfig;
		
		render();
	};
};

export default app;
