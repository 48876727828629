import ReactDOM from 'react-dom';
import React from 'react';
import Combobox from '@salesforce-mc/mobile-common/components/combobox';

import Popover from '@salesforce/design-system-react/components/popover';
import Button from '@salesforce/design-system-react/components/button';
import IconSettings from '@salesforce/design-system-react/components/icon-settings';
import Icon from '@salesforce/design-system-react/components/icon';

import Template from '../../templates/push-header.html';
import { IOS_MAX_BYTE_COUNT, ANDROID_MAX_BYTE_COUNT } from '../../constants';

const pushHeader = function (dom, config, callback) {
	const { i18n } = config;

	let device = 'iphone';
	let deviceType = 'lock-screen';
	
	const sendCallback = () => {
		callback({
			device,
			deviceType
		});
	};
		
	const renderHelpBubble = (message) => {
		// Convert message to html element to find out if it contains hyperlink
		const temp = document.createElement('div');
		temp.innerHTML = message;
		const popoverBody = temp.firstChild.textContent;
		const hyperlink = temp.querySelector('a');
		
		/* eslint-disable react/jsx-filename-extension, react/no-danger */
		ReactDOM.render(
			<IconSettings iconPath="/assets/icons">
				<Popover
					body={(<p>{popoverBody}</p>)}
					variant="feature"
					align="top right"
					heading={i18n.get('mobilepush_inapp.help')}
					icon={
						<Icon category="utility" name="info" size="small" inverse />
					}
					footerWalkthroughActions={hyperlink ? (
						<Button
							onClick={() => {
								window.open(hyperlink.getAttribute('href'), '_blank');
							}}
							variant="neutral"
						>
							{hyperlink.text}
						</Button>
					) : null}
				>
					<Button
						assistiveText={{
							icon: i18n.get('mobilepush_inapp.help')
						}}
						iconCategory="utility"
						iconName="info"
						iconSize="small"
						iconVariant="container"
						variant="icon"
					/>
				</Popover>
			</IconSettings>,
			dom.querySelector('#tooltip-container')
		);
		/* eslint-enable */
	};

	const renderByteCountUnavailable = () => {
		const text = i18n.get('mobilepush_inapp.byte_count_unavailable');
		
		dom.querySelector('#warning-container').classList.remove('hidden');
		dom.querySelector('#tooltip-container').classList.remove('hidden');
		dom.querySelector('#byte-count').innerHTML = text;
		
		renderHelpBubble(i18n.get('mobilepush_inapp.mp-messages-bytecount'));
	};
	
	const renderByteCountAvailable = () => {
		const { byteCount } = config;
		const text = i18n.get('mobilepush_inapp.used_of_max_bytes_used');
		
		let max = IOS_MAX_BYTE_COUNT;
		
		if (device === 'android') {
			max = ANDROID_MAX_BYTE_COUNT;
		}
		
		const byteCountText = text.replace('{used}', byteCount).replace('{max}', max);
		
		dom.querySelector('#warning-container').classList.add('hidden');
		dom.querySelector('#byte-count').innerHTML = byteCountText;
		
		renderHelpBubble(i18n.get('mobilepush_inapp.mp-messages-ga4'));
	};
	
	const renderByteCount = () => {
		const { byteCount } = config;
		
		if (byteCount > -1) {
			renderByteCountAvailable();
		} else {
			renderByteCountUnavailable();
		}
	};
	
	const renderDeviceTypes = () => {
		const container = dom.querySelector('#device-view-container');
		
		let selection = { value: 'lock-screen', text: i18n.get('mobilepush_inapp.lock_screen') };
		
		const defaultConfig = {
			id: 'device-view',
			options: [
				selection,
				{ value: 'long-press', text: i18n.get('mobilepush_inapp.long_press') },
				{ value: 'alert', text: i18n.get('mobilepush_inapp.alert') },
				{ value: 'banner', text: i18n.get('mobilepush_inapp.banner') }
			],
			selection
		};
		
		let comboboxConfig;
		
		deviceType = 'lock-screen';
		
		switch (device) {
			case 'iphone':
				comboboxConfig = defaultConfig;
				break;
				
			case 'android':
				selection = { value: 'lock-screen', text: i18n.get('mobilepush_inapp.lock_screen') };
				comboboxConfig = Object.assign(defaultConfig, {
					selection,
					options: [
						selection,
						{ value: 'shade', text: i18n.get('mobilepush_inapp.shade') }
					]
				});
				deviceType = 'lock-screen';
				break;
				
			case 'apple-watch':
				selection = { value: 'short-look', text: i18n.get('mobilepush_inapp.short_look') };
				comboboxConfig = Object.assign(defaultConfig, {
					selection,
					options: [
						selection,
						{ value: 'long-look', text: i18n.get('mobilepush_inapp.long_look') }
					]
				});
				deviceType = 'short-look';
				break;
				
			default:
				comboboxConfig = defaultConfig;
		}
		
		sendCallback();
		
		this.device = new Combobox(container, comboboxConfig);

		this.device.onSelect = (response) => {
			deviceType = response.value;
			
			sendCallback();
		};
	};
	
	const renderDevices = () => {
		const container = dom.querySelector('#device-container');
		const selection = { value: 'iphone', text: i18n.get('mobilepush_inapp.iphone') };
		const comboboxConfig = {
			id: 'device',
			options: [
				selection,
				{ value: 'android', text: i18n.get('mobilepush_inapp.android') },
				{ value: 'apple-watch', text: i18n.get('mobilepush_inapp.apple_watch') }
			],
			selection
		};
		
		this.device = new Combobox(container, comboboxConfig);
		
		this.device.onSelect = (response) => {
			device = response.value;
			
			renderDeviceTypes();
		};
	};
	
	const render = () => {
		dom.innerHTML = Template;
	};
		
	const initialize = () => {
		render();
		renderDevices();
		renderDeviceTypes();
		renderByteCount();
	};
	
	initialize();
	
	this.renderByteCount = (byteCount) => {
		config.byteCount = byteCount || config.byteCount;
		
		renderByteCount();
	};
};

export default pushHeader;
