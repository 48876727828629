import moment from 'moment';
import { DEFAULT_CULTURE_CODE } from '@salesforce-mc/mobile-common/constants';

const dateTimeFormatMap = {
	ja: {
		dateFormat: 'MMMDo dddd',
		timeFormat: 'HH:mm'
	},
	de: {
		dateFormat: 'dddd, Do  MMMM',
		timeFormat: 'HH:mm'
	},
	'pt-br': {
		dateFormat: 'dddd, D [de] MMMM',
		timeFormat: 'HH:mm'
	},
	it: {
		dateFormat: 'dddd D MMMM',
		timeFormat: 'HH:mm'
	},
	fr: {
		dateFormat: 'dddd D MMMM',
		timeFormat: 'HH:mm'
	},
	'fr-ca': {
		dateFormat: 'dddd D MMMM',
		timeFormat: 'HH:mm'
	},
	es: {
		dateFormat: 'dddd, D [de] MMMM',
		timeFormat: 'HH:mm'
	}
};
const defaultFormat = {
	dateFormat: 'dddd, MMMM D',
	timeFormat: 'h:mm'
};

const DateTime = function (locale) {
	const cultureCode = locale || DEFAULT_CULTURE_CODE;

	this.locale = cultureCode;

	moment.locale(cultureCode);
};

DateTime.prototype.getLocale = function () {
	return this.locale;
};

DateTime.prototype.getCurrentDate = function (format) {
	let fmt;

	if (format) {
		fmt = format;
	} else {
		const isLocalizationNeeded = dateTimeFormatMap[moment.locale()] !== undefined;
		fmt = isLocalizationNeeded ? dateTimeFormatMap[moment.locale()].dateFormat : defaultFormat.dateFormat;
	}

	return moment().format(fmt);
};

DateTime.prototype.getCurrentTime = function (format) {
	let fmt;

	if (format) {
		fmt = format;
	} else {
		const isLocalizationNeeded = dateTimeFormatMap[moment.locale()] !== undefined;
		fmt = isLocalizationNeeded ? dateTimeFormatMap[moment.locale()].timeFormat : defaultFormat.timeFormat;
	}

	return moment().format(fmt);
};

export default DateTime;
