import Template from '../../templates/line-header.html';

const lineHeader = function (dom, config) {
	const { i18n } = config;
	
	const addEventListeners = () => {
		dom.querySelector('#portrait').addEventListener('click', (event) => {
			dom.querySelector('#landscape').classList.remove('slds-is-selected');
			
			event.currentTarget.classList.add('slds-is-selected');
			
			this.onClickOrientation('portrait');
		});
		
		dom.querySelector('#portrait').addEventListener('mouseover', (event) => {
			event.currentTarget.parentElement.querySelector('.slds-popover').classList.remove('hidden');
		});
		
		dom.querySelector('#portrait').addEventListener('mouseout', (event) => {
			event.currentTarget.parentElement.querySelector('.slds-popover').classList.add('hidden');
		});
		
		dom.querySelector('#landscape').addEventListener('click', (event) => {
			dom.querySelector('#portrait').classList.remove('slds-is-selected');
			
			event.currentTarget.classList.add('slds-is-selected');
			
			this.onClickOrientation('landscape');
		});
		
		dom.querySelector('#landscape').addEventListener('mouseover', (event) => {
			event.currentTarget.parentElement.querySelector('.slds-popover').classList.remove('hidden');
		});
		
		dom.querySelector('#landscape').addEventListener('mouseout', (event) => {
			event.currentTarget.parentElement.querySelector('.slds-popover').classList.add('hidden');
		});
	};
	
	const render = () => {
		let template = Template;
		
		template = template.replace(new RegExp('{portrait}', 'g'), i18n.get('mobilepush_inapp.portrait'));
		template = template.replace(new RegExp('{landscape}', 'g'), i18n.get('mobilepush_inapp.landscape'));
		template = template.replace('{display-preview}', i18n.get('mobilepush_inapp.display_previewer'));
		
		dom.innerHTML = template;
	};
	
	const initialize = () => {
		this.config = config || {};
		
		render();
		addEventListeners();
	};
	
	initialize();
};

export default lineHeader;
