import Template from '../../templates/sms-header.html';

const smsHeader = function (dom, config) {
	const { i18n } = config;
		
	const render = () => {
		let template = Template;
		
		template = template.replace('{display-preview}', i18n.get('mobilepush_inapp.display_previewer'));
		
		dom.innerHTML = template;
	};
	
	const initialize = () => {
		this.config = config || {};
		
		render();
	};
	
	initialize();
};

export default smsHeader;
