import Previewer from '@salesforce-mc/mobile-previewer';
import { SecurePreview } from '@salesforce-mc/contentbuilder-sdk';
import Template from '../../templates/main.html';
import LinePreviewerTemplate from '../../templates/line-previewer.html';
import Header from './line-header';

const line = function (dom, config) {
	const previewerHeader = '#previewer-header';
	const previewerContainer = '#previewer-container';
	const iframeContainer = '#line-previewer-container';
	
	const getPage = () => {
		const previewer = new Previewer(this.config);
		const page = previewer.getPage();

		return page || '';
	};

	const renderSecurePreview = () => {
		const container = dom.querySelector(iframeContainer);
		
		if (!this.securePreview) {
			this.securePreview = new SecurePreview(container, config.stackKey);
		}
		
		this.securePreview.setContent(getPage());
	};

	const hideArrows = () => {
		dom.querySelector('.left-chevron').classList.add('invisible');
		dom.querySelector('.right-chevron').classList.add('invisible');
	};
		
	const hideshowArrows = () => {
		if (this.config.template === 'lineMultiContent') {
			hideArrows();
			return;
		}

		this.config.currentColumn = parseInt(this.config.currentColumn, 10);
		
		if (this.config.currentColumn === 0) {
			hideArrows();
			
			if (this.config.columns.length > 1) {
				dom.querySelector('.right-chevron').classList.remove('invisible');
			}
		} else if ((this.config.columns.length - 1) === this.config.currentColumn) {
			dom.querySelector('.left-chevron').classList.remove('invisible');
			dom.querySelector('.right-chevron').classList.add('invisible');
		} else {
			dom.querySelector('.left-chevron').classList.remove('invisible');
			dom.querySelector('.right-chevron').classList.remove('invisible');
		}
	};
	
	const renderPreviewer = () => {
		renderSecurePreview();
	};
	
	const addEventListeners = () => {
		dom.querySelector('.left-chevron').addEventListener('click', () => {
			this.config.currentColumn -= 1;
			
			hideshowArrows();
			renderPreviewer();
		});
		
		dom.querySelector('.right-chevron').addEventListener('click', () => {
			this.config.currentColumn += 1;
			
			hideshowArrows();
			renderPreviewer();
		});
	};
	
	const renderArrows = () => {
		if (this.config.isSubscriberPreview) {
			dom.querySelector('.left-chevron').classList.remove('hidden');
			dom.querySelector('.right-chevron').classList.remove('hidden');
			
			hideshowArrows();
		}
	};
	
	const renderHeader = () => {
		const container = dom.querySelector(previewerHeader);
		
		this.header = new Header(container, config);
		
		this.header.onClickOrientation = (response) => {
			this.config.orientation = response;
			
			if (this.config.orientation === 'landscape') {
				document.querySelector('iframe').classList.add('landscape');
			} else {
				document.querySelector('iframe').classList.remove('landscape');
			}
			
			renderPreviewer();
		};
	};
	
	const render = () => {
		dom.innerHTML = Template;
		dom.querySelector(previewerContainer).innerHTML = LinePreviewerTemplate;
	};
	
	const initialize = () => {
		this.config = config || {};
		this.config.orientation = 'portrait';
		
		render();
		renderHeader();
		renderPreviewer();
		renderArrows();
		addEventListeners();
	};
	
	initialize();
	
	this.render = (updates) => {
		this.config = Object.assign(this.config, updates);

		renderPreviewer();
		renderArrows();
	};
};

export default line;
